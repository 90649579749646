.who-are-we {
  background-color: hsla(235, 91%, 13%, 1);
  background-image: url("https://res.cloudinary.com/srikanth143/image/upload/v1687448848/Layer_1_4_et0rsk.svg");
  color: white;
  height: 20%;

  background-repeat: no-repeat;
  background-position: 140% 53%;
  padding: 2em 3em;
}

.image-icons {
  height: 20%;
  background: linear-gradient(
    to right,
    hsl(262, 44%, 49%),
    hsl(204, 72%, 53%),
    hsl(189, 97%, 50%)
  );
  padding: 2rem 0;
}
.center-container {
  display: flex;
  justify-content: center;
}

.globe {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.mission-container {
  background-image: url("https://res.cloudinary.com/srikanth143/image/upload/v1686996564/Line_pattern_1_scuumb.svg");
  background-color: rgba(173, 216, 230, 0.5);
}

.vision-container {
  background-image: url("https://res.cloudinary.com/srikanth143/image/upload/v1686996564/objects_inpt5w.svg");
  background-color: #d8f3ff;
  box-shadow: -10px 0px 10px rgba(173, 216, 230, 0.5);

  background-position: 41% 56%;
}

.mission-heading {
  background-image: linear-gradient(
    to right,
    hsla(249, 100%, 81%),
    hsla(207, 67%, 53%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
