.horizontolContainer {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  white-space: nowrap;
  outline: none;
  color: white;
  background-image: url("../../../assets/images/Layer\ 2.png"),
    url("../../../assets/images/Rectangle\ 228.png");
  height: 65vh;
}
.list-container6 {
  display: flex;
  gap: 8em;
  height: 60%;
}

.horizontolContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

li {
  list-style-type: none;
}
.two {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 4em;
}
.one {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2em;
}
.one::before,
.one::after {
  content: "";
  position: absolute;
  top: 55px;
  bottom: 195px;
  width: 1px;
  background-color: #9aa2fc27;
}
.two::before,
.two::after {
  content: "";
  position: absolute;
  top: 55px;
  bottom: 195px;
  width: 1px;
  background-color: #9aa2fc27;
}
@media (max-width: 767px) {
  .one::before,
  .one::after {
    content: "";
    position: absolute;
    top: 60px;
    width: 1px;
    background-color: #9aa2fc27;
  }
}

.journey {
  font-size: 1.5rem;
  background-image: linear-gradient(96deg, #c5a4ff, #2d9fe0, #06d6fb);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 800;
}
.our-journey {
  background-image: linear-gradient(
    96deg,
    #c5a4ff,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.year {
  font-size: 1.2em;
  padding: 0.3em;
  font-style: normal;
  font-weight: 100;
}
