/* Base Styles */
.modulecontainer {
    height: 500px; 
    margin: 0;
    width: 100%;
    background-image: linear-gradient(92deg, #334DB59C, #334DB59C),
    url("../../assets/Casestudiesimgs/Path 396.png"); 
       color: white;
    background-position: 30% 29%;
    background-size: cover;
    position: relative;
 }
   
 .clientbgcontainer {
  background-image: url("../../assets/Casestudiesimgs/clientschallengebg.svg"),linear-gradient(92deg, #03083E, #03083E);
     
 }
 .radiant-border {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(45deg, #38bdf8, #6868dd) 1;
}
.image{
  max-width: 143%;
    height: auto;
}