@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600&display=swap");

@import "../src/MediaQuery.css";

:root {
  --animate-duration: 1s;

  --animate-delay: 1s;

  --animate-repeat: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.activee {
  background: linear-gradient(to right, #8e0ee9, #00aed9);
  color: #fff;
}

.monitore:hover {
  background: linear-gradient(to right, #8e0ee9, #00aed9);
  color: #fff;
}

.clicked {
  background: linear-gradient(to right, #8e0ee9, #00aed9);
  color: #fff;
}
.media {
  width: auto;
  padding: 20px !important;
}

.text-gradient {
  background: linear-gradient(
    to right,
    hsl(238, 87%, 78%),
    hsl(238, 87%, 78%),
    hsl(238, 87%, 78%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mobility-gradient {
  background: linear-gradient(
    to right,
    hsl(238, 87%, 78%),
    hsl(238, 87%, 78%),
    hsl(238, 87%, 78%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 50px;
}

.heading-text-gradient {
  background: linear-gradient(
    to right,
    hsl(248, 100%, 81%),
    hsl(190, 94%, 51%),
    hsl(190, 94%, 51%),
    hsl(190, 94%, 51%),
    hsl(190, 94%, 51%),
    hsl(190, 94%, 51%)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

ul li a:hover {
  color: #44cceb;
}

.main-container {
  background-image: linear-gradient(to right, #0b1a57c4, #071036cf, #000000ad),
    url("./assets/images/About-img.png");
  background-size: cover;
}

.know-more {
  display: flex;
  justify-content: flex-end;
  color: rgb(0, 0, 0);
  padding-top: 10px;
  text-decoration: underline;
  padding-bottom: 2rem;
}

.know-more-2 {
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
  color: #ffffff;
  padding-top: 10px;
  text-decoration: underline;
  font-weight: 400;
  padding-bottom: 2rem;
}

/* Main-Heading-section */

.main-page-container {
  background-image: url("./assets/images/Layerhero.svg"),
    url("./assets/images/Ellipse.svg"), url("./assets/images/Technologyy.png"),
    linear-gradient(to right, #0b1a57, #0b1a57, #071036e3, #000000a1),
    url("./assets/images/hero-container.png");
  background-size: 78% auto, 87% auto, 4% auto, 100% auto, cover, cover;
  background-position: left 390px top 1%, right 727px top -1px,
    right 70px top 263px, center, center;
  position: relative;
  background-repeat: no-repeat;
  background-clip: content-box;
  -webkit-background-clip: content-box;
  z-index: 1000;
  height: auto;
}

@media only screen and (max-width: 1200px) {
  .main-page-container {
    background-image: url("./assets/images/Layerhero.svg"),
      url("./assets/images/Ellipse.svg"),
      linear-gradient(to right, #0b1a57, #0b1a57, #071036e3, #000000a1),
      url("./assets/images/hero-container.png");
    background-size: 78% auto, 87% auto, 100% auto, cover, cover;
    background-position: left 390px top 1%, right 727px top -1px, center, center;
    position: relative;
    background-repeat: no-repeat;
    background-clip: content-box;
    -webkit-background-clip: content-box;
    z-index: 1000;
    height: 50%;
  }
}
/* TEXT-NAV */
.text-sm {
  font-size: 0.875rem;
  line-height: 2.4rem !important;
}

.main-page-container hr {
  border-right-width: 0;
  margin-right: 45em;
  margin-bottom: 10px;
}

.main-heading-section {
  padding-top: 8rem;
  padding-left: 3rem;
  width: 45%;
  padding-bottom: 4rem;
}

.main-heading-section h1 {
  color: #fff;
  padding: 0;
  opacity: 90%;
  font-family: GT Walsheim Pro;
  font-weight: 400;
  font-size: 78px;
  content: "";
  top: 50%;
  left: 30%;
  width: 100%;
  height: 100%;
  line-height: 120px;
}

.learn-more-link {
  color: #fff;
  font-size: 20px;
  margin-top: 20px;
  font-family: GT Walsheim Pro;
  font-weight: 300;
  text-decoration: underline;
}

.main-heading-section p {
  color: #fff;
  opacity: 90%;
  font-family: GT Walsheim Pro;
  font-weight: 300;
  font-size: 20px;
  line-height: 40px;
  padding-bottom: 2rem;
  width: 100%;
}

/* client section */

.client-card {
  display: flex;
  justify-content: space-around;
  margin-left: -50px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
}

/* button section */

.reach-us-button {
  height: 62px;
  width: 237px;
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 12px 15px 13px;
  outline: 0;
  cursor: pointer;
  position: relative;
  background: linear-gradient(90deg, #6d46b4, #06d6fb, #06d6fb, #06d6fb);
  background-size: 300% 100%;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding-right: 1rem;
  box-shadow: 3px 3px #d1ebff;
  transition: 0.3s;
}
.reach-us-button:hover {
  box-shadow: 0px 0px #d1ebff;
}

.button-container {
  height: 68px;
  background-color: #fff;
  width: 230px;
}

/* Marquee-Tag-Section */
.marque-overflow {
  overflow: hidden;
}

.marquee {
  background: linear-gradient(90deg, #6e44b2, #3493da, #6e44b2, #05d7fb);
  background-size: 300% 100%;
  z-index: 1000;
  position: relative;
}

.marquee-clientcard {
  background: transparent;
  background-size: 300% 100%;
}

@keyframes animateBg {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.text-anim-holder {
  display: inline-block;
  white-space: nowrap;
  font-size: 50px;
  position: relative;
  color: #f2f2f2;
  overflow: hidden;
}

.text-anim-holder_clientcard {
  display: inline-block;
  white-space: nowrap;
  font-size: 50px;
  position: relative;
  color: #f2f2f2;
  overflow: hidden;
}
.marquee .text-anim-holder {
  animation: marquee-left 35s linear infinite;
}
.marquee-clientcard .text-anim-holder_clientcard {
  animation: marquee-right 10s linear infinite;
}

@keyframes marquee-left {
  0% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(-65%);
  }
}

@keyframes marquee-right {
  0% {
    transform: translateX(-40%);
  }

  100% {
    transform: translateX(-1%);
  }
}

.marquee-elements {
  display: flex;
  height: 175px;
  align-items: center;
  color: #d9dfe2;
  font-family: Outfit, sans-serif;
}

.marquee-elements-clientcard {
  display: flex;
  height: 160px;
  color: #d9dfe2;
  font-family: Outfit, sans-serif;
  background-color: transparent;
}

.marquee:hover .text-anim-holder {
  animation-play-state: paused;
}

.marquee:hover {
  animation-play-state: paused;
  cursor: pointer;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-clientcard {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-elements li:hover {
  transition: transform 0.35s ease-in-out;
  letter-spacing: 2px;
  color: #ffffff;
}

/* About-us-Section */

.our-approch {
  background-image: url("./assets/images/our-approch.svg"),
    linear-gradient(to right, #0b1a57, #03083e, #03083e);
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  padding-top: 3px;
  position: relative;
  z-index: 1000;
}

.gradient-color {
  background: linear-gradient(
    to right,
    hsl(248, 100%, 81%),
    hsl(190, 94%, 51%),
    hsl(248, 100%, 81%),
    hsl(190, 94%, 51%)
  );

  height: 2px;
  border: none;
}
.About-us-section {
  background-color: #0b0423;
  background: cover;
  height: 100%;
}

.flow-container-img {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.hover-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* our-solutions-Section */

.our-solutions-container {
  background-color: #f1fffc;
  position: relative;
  z-index: 1000;
}

.our-solutions-fleet {
  background-image: linear-gradient(
    to right,
    #6a3093,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 34px;
  font-weight: 500;
}
.our-solutions-heading {
  background-image: linear-gradient(
    to right,
    #6a3093,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb
  );
  font-weight: 500;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.logo-image {
  height: 90px;
  width: 130px;
}
.solutions-container-heading-2 {
  color: #000000;
  line-height: 200px;
}
.IoT-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: 360px;
  background-color: transparent;
  margin-left: 7rem;
  border: none;
  font-size: 24px;
  color: #284ad1;
  font-weight: 500;
}
.gardient-text {
  padding-left: 2rem;
  background-image: linear-gradient(
    to right,
    #6a3093,
    #6a3093,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb,
    #06d6fb
  );
  font-weight: 500;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  opacity: 0.5;
}

/*  styles to apply on hover */
.IoT-container:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: 360px;
  background-color: #284ad1;
  margin-left: 7rem;
  color: #ffffff;
}

h5 {
  color: #ffffff;
  font-size: 55px;
  font-family: GT Walsheim Pro;
}
.main-flow-container {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  overflow: hidden;
}

/* our services */

h2 {
  color: #ffffff;
  font-family: GT Walsheim Pro;
  font-size: 48px;
}

.Our-Services-Container hr {
  background-image: linear-gradient(to right, #00aed9, #6a3093);
  border-right-width: 0;
  margin: 7px;
}

.services-tab-holder-1 {
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
}
.services-tab-holder-2 {
  display: flex;
  flex-direction: row;
}

.box-container {
  width: 100%;
  max-width: 365px;
  padding-left: 7rem;
  margin: 0.6rem 4rem;
  border-radius: 0;
}

.box-container a {
  text-decoration: none;
}

.OurServiceHeading {
  height: 67px;
  color: #000000;
  background-color: #d8f3ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GT Walsheim Pro;
}

.Industries-Section {
  height: 100vh;
  background-color: #03083e;
  background-image: url("./assets/images/industryanim.png");
  background-size: cover;
}
.Industries-Section h1 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: GT Walsheim Pro;
  font-size: 55px;
  padding-left: 80px;
  padding-top: 35px;
  color: black;
}

@media screen and (max-width: 991px) {
  .section-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .servebnr-serve-bnr-rt {
    width: 100%;
    margin-left: 0px;
    padding-right: 0px;
  }
}

.Testimonials-section {
  height: 100%;
  overflow: hidden;
  padding-right: 50px;
  padding-left: 50px;
  background-color: #d8f3ff;
}

.service-bnr-wrap {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 50px;
}

.serve-bnr-lft {
  display: block;
  width: 50%;
  min-height: 590px;
  padding: 30px;
  text-align: right;
  background-image: url("./assets/images/service_bnr_img-min.jpg");
  background-position: 0px 0px;
  background-size: cover;
  opacity: 1;
  position: relative;
}

.serve-bnr-img {
  width: 850px;
  height: 700px;
  background-size: cover;
  object-fit: cover;
}

.servebnr-serve-bnr-rt {
  margin-left: 30px;
  padding-right: 150px;
  padding-left: 0px;
}

.serv-bnr-list-wrap {
  padding-left: 0px;
  border-left: 2px solid hsla(0, 0%, 100%, 0.2);
}

.srv-bnr-item-head.active {
  margin-top: 0px;
  padding-top: 0px;
  opacity: 1;
  color: #000000;
}

.srv-bnr-item-head {
  font-size: 40px;
  line-height: 170%;
  font-weight: 700;
  color: #e4e4e4;
}
.srv-bnr-item-head:hover {
  color: #000000;
  cursor: pointer;
}
.active-title {
  color: #000000;
}
.srv-bnr-desc {
  overflow: hidden;
  font-size: 16px;
  line-height: 160%;
  font-weight: 400;
  opacity: 0.8;
  color: #000000 !important;
}

.percentage-bar {
  position: absolute;
  left: -3px;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 4px;
  height: 0%;
  border-radius: 30px;
  background-color: #ffffff;
  animation: height 0.3s ease;
}

@keyframes height {
  0% {
    height: 200%;
  }
  100% {
    height: 50%;
  }
}

.service-bnr-item.active .percentage-bar {
  animation: percentBar 5s forwards ease-in-out;
  background-color: #00aed9;
}

@keyframes percentBar {
  0% {
    top: 0%;
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

.service-bnr-item {
  position: relative;
  margin-bottom: 30px;
  padding-left: 30px;
}

.industries {
  border: 1px solid #b3b3b3;
  padding: 30px;
  margin-bottom: 40px;
}

.footer-container {
  background-image: url("./assets/images/footer-layer.svg"),
    linear-gradient(to right, #6e44b2, #3493da);
  position: relative;
  z-index: 1000;
}

/* Testimonials */
.Testimonials-container {
  padding: 25px;
  background-image: linear-gradient(to right, #bbeaff, #d8f3ff);
  z-index: 1000;
  position: relative;
}

.Review-contain {
  background-image: url("./assets/images/review.svg"),
    linear-gradient(#03083e, #03083e);
  height: auto;
  width: 993px;
  margin: 3em auto;
}
.in-icon {
  width: 35px;
}
.animatesvg {
  position: absolute;
  width: 100%;
  top: -200px;
  opacity: 0.3;
}

/* Tab screens */
@media (max-width: 1700px) {
  .main-page-container {
    background-size: 78% auto, 87% auto, 5% auto, 100% auto, cover, cover;
    background-position: left 390px top 1%, right 592px top 98px,
      right 70px top 263px, center, center;
    position: relative;
    background-repeat: no-repeat;
    background-clip: content-box;
    -webkit-background-clip: content-box;
    z-index: 1000;
    height: auto;
    width: 100%;
  }

  .main-page-container hr {
    border-right-width: 0;
    margin-right: 10em;
  }
  .main-heading-section {
    margin: 30px;
    padding-top: 8rem;
    padding-left: 3rem;
    width: 60%;
    padding-bottom: 5rem;
  }
  .client-card {
    display: flex;
    justify-content: space-around;
    margin-left: 4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
  }

  .main-heading-section h1 {
    margin: 0px;

    width: 100%;
    padding-bottom: 0rem;
    overflow: hidden;
    font-size: 45px;
    line-height: 5rem;
  }
  .client-card img {
    height: 100%;
    width: 100px;
  }
  .Review-container {
    width: 90%;
  }
  .Testimonials-container {
    height: 100%;
  }
  .review-client-image {
    position: relative;
    height: 40px;

    left: -13%;
    top: -46px;
  }
}

/* Mobile screens */

@media (max-width: 767px) {
  .client-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .client-card img {
    width: 100%;
    margin: 10px;
  }
}

/* Computer screens */
@media (min-width: 1025px) {
  .Testimonials-container {
    height: 100%;
  }
}
@media screen and (max-width: 991px) {
  .main-page-container {
    background-image: url("./assets/images/Layerhero.svg"),
      url("./assets/images/Ellipse.svg"),
      linear-gradient(to right, #0b1a57, #0b1a57, #071036e3, #000000a1),
      url("./assets/images/hero-container.png");
    background-size: 112% auto, 250% auto, 265% auto, cover, cover;
    background-position: left 6px top 20%, right -250px top 104px, center,
      center;
    background-repeat: no-repeat;
    background-clip: content-box;
    -webkit-background-clip: content-box;
    z-index: 1000;
    height: 100%;
    width: 100%;
  }

  .reach-us-button {
    margin-top: 10px;
  }

  .marquee-elements {
    overflow: hidden;
  }
  .main-heading-section {
    margin: 0px;
    padding-top: 4rem;
    padding-left: 3rem;
    width: 100%;
    padding-bottom: 2rem;
    overflow: hidden;
  }
  .main-heading-section h1 {
    margin: 0px;
    width: 100%;
    padding-bottom: 1rem;
    overflow: hidden;
    font-size: 45px;
    line-height: 4rem;
  }
  .main-heading-section p {
    color: #fff;
    opacity: 90%;
    font-family: GT Walsheim Pro;
    font-weight: 300;
    font-size: 19px;
    line-height: 40px;
    padding-bottom: 2rem;
    width: 100%;
  }
  .text-anim-holder {
    overflow: hidden;
  }
  .marque-overflow {
    overflow: hidden;
  }
  .About-us-section {
    overflow: hidden;
  }

  .client-card img {
    height: 100%;
    width: 100px;
  }
  .client-card {
    overflow: hidden;
    display: flex;
    margin-left: 50px;
    justify-content: space-between;
    padding-right: 50px;
  }
  .hover-button-container button {
    font-size: 9px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .know-more-2 {
    display: flex;
    justify-content: flex-end;
    padding-right: 21px;
    color: #ffffff;
    padding-top: 10px;
    text-decoration: underline;
    font-weight: 400;
    font-size: 9px;
    padding-bottom: 2rem;
  }
  .our-approch hr {
    border-right-width: 0;
  }
  .general-iot-heading {
    font-size: 25px;
    padding-left: 50px;
  }

  .Review-container {
    width: 90%;
    height: 100%;
  }
  .footer-components {
    gap: 0;
  }

  .Review-contain {
    width: auto !important;
    height: auto !important;
  }
  .Testimonials-container h1 {
    font-size: 30px !important;
    padding-bottom: 0 !important;
  }
}

/* Our Team Start */
.Our-Main-Container {
  background-image: linear-gradient(to right, #0b1a57c4, #071036cf, #000000ad),
    url("./assets/images/OurTeam.png");
  background-repeat: no-repeat;
  background-color: hsla(235, 91%, 13%, 1);
  background-size: cover;
}
.Our-Managment {
  background-image: url("./assets/images/OurManagement-layer1.svg"),
    url("./assets/images/OurMangment-layer2.svg");
  color: white;
  height: auto;
  background-position: 0% 30%;
  padding: 4em 3rem;
}

.Our-Services-Container {
  height: 100%;
  background: url("./assets/images/Services.png"),
    linear-gradient(to bottom, #6e43b2 1%, #3495db 30%, #ffffff, #ffffff 100%);
  background-repeat: no-repeat;
  background-size: 5% auto, 110%, cover;
  background-position: left 50px top 20%, top;
  padding-right: 80px;
  position: relative;
}
.background-image {
  background: url("./assets/images/ourservices-layer-1.svg");
  background-repeat: no-repeat;
  background-size: 74%;
  background-position: left 764px top 99%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.our-image {
  width: auto;
  height: auto;
}
.our-text {
  border-bottom: 2px solid;
  width: 70%;
  padding-bottom: 10px;
}
.join-team {
  background: url("./assets/images/join-our-team.png"),
    linear-gradient(to right, hsl(235, 89%, 70%), hsl(177, 87%, 79%));

  background-size: 36%, 100% auto;
  background-position: 110%, right 0% top -0;
  background-repeat: no-repeat;
  height: 44vh;
}

.join-i {
  width: auto;
  height: auto;
}

/* Contact Start */
.main-contact {
  background-image: url("./assets/images/Contact-layer.png"),
    url("./assets/images/Ellipse.svg"), url("./assets/images/Contact-path.png");
  background-size: cover, 57% auto, cover;
  background-position: center, right 129% top 65px;
  position: relative;
  background-repeat: repeat, no-repeat;
  height: auto;
  width: auto;
}

.Contact-box {
  max-width: 750px;
  min-height: 800px;
  position: relative;
  background-color: #dffffc;
  padding: 25px 30px;
  --s: -7px;
  --x: 15px;
  --y: 15px;
  --b: 0px;
}

.box-shadow:before {
  content: "";
  position: absolute;
  inset: calc(-1 * var(--s) - 2px);
  transform: translate(var(--x), var(--y));
  clip-path: polygon(
    -10vmax -10vmax,
    100vmax -10vmax,
    100vmax 100vmax,
    -100vmax 100vmax,
    -100vmax -100vmax,
    calc(0px + var(--s) - var(--x)) calc(0px + var(--s) - var(--y)),
    calc(0px + var(--s) - var(--x)) calc(99.7% - var(--s) - var(--y)),
    calc(99.7% - var(--s) - var(--x)) calc(99.7% - var(--s) - var(--y)),
    calc(99.7% - var(--s) - var(--x)) calc(0px + var(--s) - var(--y)),
    calc(0px + var(--s) - var(--x)) calc(0px + var(--s) - var(--y))
  );

  background: linear-gradient(180deg, #6452ba 0%, #2f9bde 46%, #07d4fa 100%);
}

.radio {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #9aedff;
  border-radius: 50%;
}

.radio input:checked ~ .checkmark {
  background-color: #0ecbf5;
  border: 5px solid #9aedff;
}

.input-box {
  margin-bottom: 35px;
  width: calc(100% / 2 - 20px);
}

.formInput {
  height: 30px;
  width: 100%;
  outline: none;
  color: #6d6d6d;
  background-color: transparent;
  border-bottom: 2px solid #6d6d6d;
}
.formInputText {
  height: 70px;
  width: 100%;
  outline: none;
  color: #6d6d6d;
  background-color: transparent;
  border: 2px solid #6d6d6d;
  padding: 10px;
}
.select {
  background-color: #0ecbf5d6;
  color: white;
}
.talkus {
  height: 30px;
  width: 100%;
  outline: none;
  color: #6d6d6d;
  background-color: transparent;
  border-bottom: 2px solid #6d6d6d;
}

.service {
  border: 2px solid #000;
  padding: 10px 30px;
  cursor: pointer;
}

.submit-button {
  height: 62px;
  width: 150px;
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 12px 15px 13px;
  outline: 0;
  cursor: pointer;
  position: relative;
  background: linear-gradient(90deg, #6d46b4, #06d6fb, #06d6fb, #06d6fb);
  background-size: 300% 100%;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding-right: 1rem;
  box-shadow: 3px 3px #a8a9aa7a;
  transition: 0.3s;
}
.submit-button:hover {
  box-shadow: 0px 0px #d1ebff;
}
.join-us-button {
  height: 62px;
  width: 237px;
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 12px 15px 13px;
  outline: 0;
  cursor: pointer;
  position: relative;
  background: linear-gradient(90deg, #6d46b4, #06d6fb, #06d6fb, #06d6fb);
  background-size: 300% 100%;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding-right: 1rem;
  box-shadow: 3px 3px #d1ebff;
  transition: 0.3s;
}
.join-us-button:hover {
  box-shadow: 0px 0px #d1ebff;
}
.iframe {
  width: 600px;
  height: 350px;
}

/* Our Solution Start */
.Our-Solution-Container {
  background-image: url("./assets/images/OurSolutionLayer.png"),
    url("./assets/images/OurSolution.png");
  background-repeat: no-repeat;
  background-color: hsla(235, 91%, 13%, 1);
  background-size: cover;
  background-position: center;
}
.our-iiot-container {
  background-image: url("./assets/images/OurSolutionLayer.png"),
    url("./assets/images/iiot.png");
  background-repeat: no-repeat;
  background-color: hsla(235, 91%, 13%, 1);
  background-size: cover;
  background-position: center;
}
.our-mobility-container {
  background-image: url("./assets/images/Mobility-1.png"),
    url("./assets/images/Mobility-2.png");
  background-repeat: no-repeat;
  background-color: hsla(235, 91%, 13%, 1);
  background-size: cover;
}
.Elevate {
  background-color: hsla(235, 91%, 13%, 1);
  color: white;
  padding: 3em 3em;
}

.smart-main-container {
  background-image: url("./assets/images/SmartParkingLayer.png");
  border-radius: 25px;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.058));
}
.access-main-container {
  background-color: #d1ffe6;
  border-radius: 25px;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.058));
}
.facility-main-container {
  background-color: #fee5ff;
  border-radius: 25px;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.058));
}
.Act-main-container {
  background-color: #d8fffe;
  border-radius: 25px;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.058));
}

.meets-main-container {
  background: linear-gradient(to right, hsl(235, 89%, 70%), hsl(177, 87%, 79%));
}

/* Our Solution End */

.drop {
  list-style: none;
  padding: 0;
}

.drop li {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  background-color: rgba(3, 8, 63, 0.551);
  width: 150%;
  height: auto;
  padding: 20px;
  z-index: 1;
}

.scrollable-content {
  display: flex;
}

.item {
  width: 300px;
  height: 200px;
  background-color: #e0e0e0;
  margin: 10px;
}

.drop li:hover .dropdown {
  display: block;
}
.dropdown li {
  font-size: 18px;
  text-align: left;
}

.filter {
  filter: drop-shadow(0 0 10px #0003);
}

.slick-dots li button:before {
  font-size: 17px !important;
  line-height: 87px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 35px !important;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -60px !important;
}
.slick-next {
  right: -50px !important;
}
.our-team-res img {
  height: auto;
  width: 400px;
}

.slick-prev:before,
.slick-next:before {
  color: rgba(3, 8, 63, 0.347) !important;
}
.slick-vertical .slick-slide {
  display: block;
  height: 750px !important;
  border: 1px solid transparent;
}

/* icon-1 */
.icon-1 img {
  transition: all 0.2s ease;
}
.icon-1 p {
  transition: all 0.2s ease;
}
.globe {
  transition: all 1.5s ease-in-out;
}
.rocket {
  transition: all 0.5s ease;
}
.icon p {
  transition: all 0.6s ease;
}
.year p {
  transition: all 0.6s ease;
}
.year:hover p {
  transform: scale(1.15);
}
.year .yers {
  transition: all 0.6s ease;
}
.year:hover .yers {
  transform: rotateY(180deg);
}
.icon .client {
  transition: all 0.6s ease;
}
.icon:hover .client {
  transform: rotateY(180deg);
}
.icon:hover p {
  transform: scale(1.15);
}
.icon:hover .rocket {
  translate: 20px -10px;
}
.icon:hover .globe {
  rotate: y 360deg;
}
.icon-1:hover img {
  transform: rotate(10deg) scale(1.2);
}

.icon-1:hover p {
  transform: scale(1.15);
}
/* icon-2 */
.icon-2 img {
  transition: all 0.2s ease;
}
.icon-2 p {
  transition: all 0.2s ease;
}

.icon-2:hover img {
  transform: rotate(10deg) scale(1.2);
}

.icon-2:hover p {
  transform: scale(1.15);
}
/* icon-3 */
.icon-3 img {
  transition: all 0.2s ease;
}
.icon-3 p {
  transition: all 0.2s ease;
}

.icon-3:hover img {
  transform: rotate(10deg) scale(1.2);
}

.icon-3:hover p {
  transform: scale(1.15);
}
/* icon-4 */
.icon-4 img {
  transition: all 0.2s ease;
}
.icon-4 p {
  transition: all 0.2s ease;
}

.icon-4:hover img {
  transform: rotate(10deg) scale(1.2);
}

.icon-4:hover p {
  transform: scale(1.15);
}
.required {
  color: red;
  margin-left: 4px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #05d7fb;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
:root {
  --card-height: 10vw;
  --card-margin: 10vw;
  --card-top-offset: 4rem;
  --numcards: 4;
  --outline-width: 0px;
}

#card_1 {
  --index: 1;
}

#card_2 {
  --index: 2;
}

#card_3 {
  --index: 3;
}

#card_4 {
  --index: 4;
}

.card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--card-top-offset));
}

@supports (animation-timeline: view()) {
  .card {
    --index0: calc(var(--index) - 2); /* 0-based index */
    --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
    --reverse-index0: calc(
      var(--reverse-index) - 1
    ); /* 0-based reverse index */
  }

  @keyframes scale {
    to {
      transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
      left: 0px;
    }
  }

  #cards {
    --numcards: 4;
    view-timeline-name: --cards-element-scrolls-in-body;
  }

  .card__content {
    --start-range: calc(var(--index0) / var(--numcards) * 50%);
    --end-range: calc((var(--index)) / var(--numcards) * 100%);
    animation: linear scale forwards;
  }
}

.card__content > div {
  width: 100%;
}
.card__content {
  animation-timeline: --cards-element-scrolls-in-body;
  animation-range: exit-crossing var(--start-range) exit-crossing
    var(--end-range);
  transform-origin: 50% 0%;
  will-change: transform;
}

.dropdown2 {
  display: none;
  position: absolute;
  top: 100%;
  left: -30px;
  background-color: rgba(3, 8, 63, 0.551);
  width: 323%;
  height: auto;
  padding: 20px;
  z-index: 1;
}
.drop li:hover .dropdown2 {
  display: block;
}
.dropdown2 li {
  font-size: 17px;
  text-align: left;
  line-height: 50px;
}
.dropdown3 {
  display: none;
  position: absolute;
  top: 100%;
  background-color: rgba(3, 8, 63, 0.551);
  width: auto;
  height: auto;
  padding: 10px 20px;
  z-index: 1;
}
.drop li:hover .dropdown3 {
  display: block;
}
.dropdown3 li {
  font-size: 17px;
  text-align: left;
  line-height: 50px;
}
.dropdown4 {
  display: none;
  position: absolute;
  top: 100%;
  background-color: rgba(3, 8, 63, 0.551);
  width: auto;
  height: auto;
  padding: 10px 20px;
  z-index: 1;
}
.drop li:hover .dropdown4 {
  display: block;
}
.dropdown4 li {
  font-size: 17px;
  text-align: left;
  line-height: 50px;
}
