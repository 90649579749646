/* Media Query for Mobile Devices */

@media (min-width: 320px) and (max-width: 480px) {
  .who-are-we {
    background-position: 120% 63% !important;
    background-size: cover !important;
  }
  .dropdown li:hover {
    color: #00aed9;
  }
  .horizontol {
    background-image: url("./assets/images/Layer\ 2.png"),
      url("./assets/images/Rectangle\ 228.png");
    padding-bottom: 30px;
    position: relative;
  }
  .dropdown {
    left: -15px !important;
    width: 111% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .dropdown2 {
    left: -15px !important;
    width: 111% !important;
    height: auto !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .dropdown4 {
    left: -17px !important;
    width: 111% !important;
    height: auto !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 0px !important;
  }
  .dropdown3 {
    left: -17px !important;
    width: 113% !important;
    height: auto !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 0px !important;
  }
  .dropdown li {
    font-size: 18px;
    text-align: center !important;
  }
  .dropdown2 li {
    font-size: 14px !important;
    text-align: center !important;
  }
  .dropdown3 li {
    text-align: center !important;
  }
  .dropdown4 li {
    text-align: center !important;
  }
  .dropdown li:hover {
    color: #00aed9;
  }
  .dropdown2 li:hover {
    color: #00aed9;
  }
  .access {
    padding: 1rem;
  }
  .journey {
    font-size: 20px !important;
  }
  .ev-1 {
    translate: 0px 77px;
    width: 500px;
  }
  .icon {
    margin-bottom: 10%;
  }
  .reach-us-button {
    height: 51px !important;
    width: 171px !important;
    color: #ffffff;
    border: none;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 12px 15px 13px;
    outline: 0;
    cursor: pointer;
    position: relative;
    background: linear-gradient(90deg, #8e0ee9, #00aed9, #8e0ee9, #00aed9);
    background-size: 300% 100%;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    padding-right: 1rem;
    box-shadow: 3px 3px #d1ebff;
    transition: 0.3s;
  }
  .menu {
    background-color: rgba(3, 8, 63, 0.551);
    padding: 1rem;
    margin-top: 20px;
  }
  .logo-image {
    width: 110px !important;
    height: 70px !important;
  }
  .main-container h1 {
    font-size: 40px;
    /* padding: 70px; */
  }
  .main-container p {
    text-align: justify;
  }
  .Our-Main-Container p {
    font-size: 14px;
    padding: 0 10px 0 10px;
  }
  .Our-Main-Container button {
    margin-top: 25px;
    margin-bottom: 30px;
  }
  .Our-Main-Container h1 {
    font-size: 45px !important;
  }
  .join-team h4 {
    font-size: 14px;
  }
  .join-team h2 {
    font-size: 14px;
    margin-top: 10px;
  }
  .join-team {
    background-size: 75%, 100% auto !important;
    background-position: right -53% top 18px, right 0% top 0px !important;
    padding-left: 1rem !important;
    padding-right: 0rem !important;
    background-repeat: no-repeat;
    height: auto !important;
  }
  .join-i {
    margin-top: 2rem;
  }
  .input-box {
    margin-bottom: 15px;
    width: 100%;
  }
  .input-box span {
    font-size: 20px;
  }
  .input-box {
    width: auto !important;
  }
  .radio {
    display: block;
  }
  .mission-container {
    padding: 3rem;
  }
  .vision-container {
    padding: 3rem;
  }
  .footer-container {
    background-image: linear-gradient(to right, #6e44b2, #3493da) !important;
  }
  .footert {
    font-size: 14px !important;
  }
  .join-us-button {
    height: 50px !important;
    width: 170px !important;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 10px 10px 10px !important;
    /* animation: animateBg 4s linear infinite; */
    padding-right: 1rem;
    box-shadow: 3px 3px #d1ebff;
    transition: 0.3s;
  }
  .learn-more-link {
    font-size: 14px !important;
  }
  .main-heading-section p {
    font-size: 18px;

    text-align: justify;
  }
  .Our-Managment h2 {
    font-size: 30px !important;
  }
  .client-card img {
    width: 100px;
  }
  .Contact-box {
    max-width: 700px;
    height: auto !important;
    position: relative;
    background-color: #dffffc;
    padding: 25px 30px;
    --s: -0 !important;
    --x: 0 !important;
    --y: 0 !important;
    --b: 0 !important;
  }
  .main-contact {
    background-size: cover, 158% auto, cover !important;
    background-position: center, right 46% top 99px !important;
    position: relative;
    background-repeat: repeat, no-repeat;
    height: auto;
    width: auto;
  }
  .main-contact h1 {
    font-size: 44px !important;
  }
  .contact-res {
    padding: 0 !important;
    padding-top: 40px !important;
  }
  .contact-res h3 {
    font-size: 22px;
  }
  .contact-res p {
    width: auto;
    font-size: 18px;
  }
  .iframe {
    width: 100% !important;
    height: 100% !important;
  }
  .Our-Solution-Container h1 {
    font-size: 50px !important;
    padding: 10% !important;
    padding-bottom: 20% !important;
  }
  .Elevate h2 {
    font-size: 27px !important;
  }
  .Elevate {
    padding: 3em 3em !important;
  }
  .smart-main-container h3 {
    font-size: 35px !important;
  }
  .access-main-container h3 {
    font-size: 30px !important;
  }
  .facility-main-container h3 {
    font-size: 30px !important;
  }
  .meets-main-container h2 {
    font-size: 23px !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .meets-main-container a {
    font-size: 13px;
  }
  .our-solutions-heading {
    font-size: 40px !important;
  }
  .Our-Services-Container h1 {
    font-size: 45px !important;
  }
  .Our-Services-Container p {
    font-size: 18px !important;
  }
  .our-approch-res {
    padding-bottom: 4rem !important;
  }
  .solutions {
    margin-top: -62px !important;
    /* padding-bottom: 20% !important; */
  }
  .solutions h1 {
    font-size: 35px !important;
  }
  .our-services-res {
    padding: 0 !important;
  }
  .Review-contain {
    width: auto !important;
    height: auto !important;
  }
  .Review-contain p {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .Testimonials-container {
    padding: 35px !important;
  }
  .industries {
    padding: 15px !important;
  }
  .Our-Services-Container {
    padding-top: 0 !important;
    background: linear-gradient(
      to bottom,
      #6e43b2 1%,
      #3495db 30%,
      #ffffff,
      #ffffff 100%
    ) !important;
    background-size: cover !important;
    background-position: top !important;
    padding-right: 0px !important;
  }
  .scroll-slider {
    display: none !important;
  }
}

@media (min-width: 393px) and (max-width: 414px) {
  .Contact-box h3 {
    font-size: 30px !important;
  }
  .access {
    padding: 1rem !important;
  }
  .main-contact {
    background-size: cover, 262% auto, cover !important;
    background-position: center, right 52% top 20px !important;
    position: relative;
    background-repeat: repeat, no-repeat;
    height: auto;
    width: auto;
  }
  .soulbutton button {
    margin-bottom: 20px;
    font-size: 25px !important;
  }
  .our-services-res {
    padding: 0 !important;
  }
  .dropdown {
    left: -15px !important;
    width: 113% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .dropdown li {
    font-size: 18px;
    text-align: center !important;
  }
  .dropdown li:hover {
    color: #00aed9;
  }
  .Our-Services-Container {
    padding-right: 0px !important;
    background-position: 0px, left 143px top 90%, top !important;
  }
  .scroll-slider {
    display: none !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .Our-Services-Container {
    background-position: left 3px top 12%, left 143px top 72%, top !important;
  }
  .Connect-img {
    width: auto !important;
    height: 390px !important;
  }
  .Analyze-img {
    width: auto !important;
    height: 350px !important;
  }
  .Mesure-img {
    width: auto !important;
    height: 400px !important;
  }
  .Act-img {
    width: auto !important;
    height: 400px !important;
  }
  .ev-1 {
    width: auto;
    height: 400px !important;
  }
  .Facility-img {
    width: auto !important;
    height: 450px !important;
  }
  .monito {
    width: auto !important;
  }
  .Security-img {
    width: auto !important;
    height: 400px !important;
  }
  .main-container h1 {
    font-size: 70px;
  }
  .media {
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .access {
    padding: 2rem !important;
  }
  .dropdown {
    left: -15px !important;
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .dropdown3 {
    left: -15px !important;
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 0px !important;
  }
  .dropdown4 {
    left: -15px !important;
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 0px !important;
  }
  .dropdown2 {
    left: -15px !important;
    width: 105% !important;
    height: auto !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .dropdown li {
    font-size: 18px;
    text-align: center !important;
  }
  .dropdown3 li {
    text-align: center !important;
  }
  .dropdown4 li {
    text-align: center !important;
  }
  .dropdown2 li {
    font-size: 18px;
    text-align: center !important;
  }
  .dropdown li:hover {
    color: #00aed9;
  }
  .dropdown2 li:hover {
    color: #00aed9;
  }
  .Our-Main-Container h1 {
    font-size: 45px;
  }
  .Our-Main-Container p {
    font-size: 18px;
    padding: 0 10px 0 10px;
  }
  .Our-Main-Container button {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .main-page-container {
    background-size: 97% auto, 150% auto, 100% auto, cover, cover !important;
    background-position: left 20px top 15%, right -10px top 70px, center, center !important;
  }
  .mission-container {
    padding: 3rem;
  }
  .vision-container {
    padding: 3rem;
  }
  .menu {
    background-color: rgba(3, 8, 63, 0.551);
    padding: 1rem;
    margin-top: 20px;
  }
  .Our-Managment p {
    font-size: 20px;
  }
  .scroll-slider {
    display: none !important;
  }
  .Contact-box {
    max-width: 700px;
    height: auto !important;
    position: relative;
    background-color: #dffffc;
    padding: 25px 30px;
    --s: -0 !important;
    --x: 0 !important;
    --y: 0 !important;
    --b: 0 !important;
  }
  .iframe {
    width: 100% !important;
    height: 350px !important;
  }
  .Our-Solution-Container h1 {
    font-size: 35px !important;
    padding: 0% !important;
    padding-bottom: 20% !important;
  }
  .our-services-res {
    padding: 0 !important;
  }
  .input-box {
    width: auto !important;
  }
  .join-team {
    background-size: 50%, 100% auto !important;
    background-position: right -18% top -3px, right 0% top -0 !important;
    padding: 0 0 0 2rem;
    background-repeat: no-repeat;
    height: auto !important;
  }
  .join-us-button {
    height: 60px !important;
    width: 180px !important;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 10px 10px 10px !important;
    /* animation: animateBg 4s linear infinite; */
    padding-right: 1rem;
    box-shadow: 3px 3px #d1ebff;
    transition: 0.3s;
  }
  .our-team-res {
    width: auto;
    height: auto;
  }
  .join-team h4 {
    font-size: 23px;
  }
  .join-team h2 {
    font-size: 31px;
  }
  .industries {
    padding: 8px !important;
  }
  .Review-contain {
    width: 600px !important;
    height: auto !important;
  }
  .Review-contain p {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .Testimonials-container {
    padding: 35px !important;
  }
  .Testimonials-container h1 {
    font-size: 35px !important;
  }
  .main-contact {
    background-size: cover, 107% auto, cover !important;
    background-position: center, right -242% top 86px !important;
  }
  .Our-Services-Container {
    padding-right: 0px !important;
    background-color: linear-gradient(
      to bottom,
      #6e43b2 1%,
      #3495db 30%,
      #ffffff,
      #ffffff 100%
    ) !important;
  }
  .soulbutton button {
    font-size: 15px !important;
  }
  .Our-Managment h2 {
    text-align: center !important;
  }
  .access {
    padding: 1rem;
  }
}

/* Media Query for Tablets Ipads portrait mode */

@media (min-width: 769px) and (max-width: 1024px) {
  .Our-Services-Container {
    background-position: left 3px top 10%, left 143px top 72%, top !important;
  }
  .Connect-img {
    width: auto !important;
    height: 450px !important;
  }
  .Analyze-img {
    width: auto !important;
    height: 400px !important;
  }
  .Mesure-img {
    width: auto !important;
    height: 450px !important;
  }
  .Act-img {
    width: auto !important;
    height: 400px !important;
    margin-top: 50px !important;
  }
  .ev-1 {
    width: auto;
    height: 400px !important;
  }
  .Facility-img {
    width: auto !important;
    height: 450px !important;
  }
  .monito {
    width: auto !important;
  }
  .Security-img {
    width: auto !important;
    height: 400px !important;
  }
  .main-container h1 {
    font-size: 50px;
  }
  .dropdown {
    left: -15px !important;
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .dropdown3 {
    left: -15px !important;
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 0px !important;
  }
  .dropdown4 {
    left: -15px !important;
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 0px !important;
  }
  .dropdown2 {
    left: -15px !important;
    width: 105% !important;
    background-color: rgb(3, 8, 63) !important;
    z-index: 1;
    margin: auto !important;
    padding: 28px !important;
  }
  .dropdown li {
    font-size: 18px;
    text-align: center !important;
  }
  .dropdown3 li {
    text-align: center !important;
  }
  .dropdown4 li {
    text-align: center !important;
  }
  .dropdown2 li {
    font-size: 18px;
    text-align: center !important;
  }
  .dropdown li:hover {
    color: #00aed9;
  }
  .dropdown2 li:hover {
    color: #00aed9;
  }
  .image-icon {
    padding: 2rem 2rem !important;
  }
  .access {
    padding: 1rem;
  }
  .Our-Main-Container p {
    font-size: 25px;
  }
  .join-team h2 {
    font-size: 32px !important;
  }
  .Contact-box {
    max-width: 700px;
    height: auto !important;
    position: relative;
    background-color: #dffffc;
    padding: 25px 30px;
    --s: -0 !important;
    --x: 0 !important;
    --y: 0 !important;
    --b: 0 !important;
  }
  .menu {
    background-color: rgba(3, 8, 63, 0.551);
    padding: 1rem;
    margin-top: 20px;
  }
  .footert li a {
    font-size: 14px !important;
  }
  .mission-container {
    padding: 3rem;
  }
  .vision-container {
    padding: 3rem;
  }
  .join-team h4 {
    font-size: 23px;
  }
  .join-team h2 {
    font-size: 34px;
  }
  .join-img-res {
    margin-left: 2px;
  }
  .join-img-res h3 {
    font-size: 28px;
  }
  .join-img-res p {
    font-size: 15px;
  }
  .Our-Managment p {
    font-size: 20px;
  }
  .Our-Managment h3 {
    font-size: 35px;
  }
  .join-team {
    background-size: 50%, 100% auto !important;
    background-position: right -18% top -16px, right 0% top -0 !important;
    padding: 0 0 0 2rem;
    background-repeat: no-repeat;
    height: auto !important;
  }
  .join-us-button {
    height: 60px !important;
    width: 180px !important;
    color: #ffffff;
    border: none;
    font-size: 15px !important;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 10px 10px 10px !important;
    /* animation: animateBg 4s linear infinite; */
    padding-right: 1rem;
    box-shadow: 3px 3px #d1ebff;
    transition: 0.3s;
  }
  .Contact-box {
    max-width: 700px;
    height: auto !important;
    position: relative;
    background-color: #dffffc;
    padding: 25px 30px;
    --s: -7px;
    --x: 15px;
    --y: 15px;
    --b: 0px;
  }
  .input-box span {
    font-size: 20px;
  }
  .input-box {
    width: auto !important;
  }
  .contact-res h3 {
    font-size: 30px;
  }
  .contact-res p {
    font-size: 25px !important;
    width: auto;
  }
  .main-contact {
    background-size: cover, 121% auto, cover !important;
    background-position: center, right -14% top 64px !important;
  }
  .contact-res {
    padding: 3rem !important;
  }
  .contact-res h3 {
    font-size: 35px;
  }
  .contact-res p {
    width: auto;
    font-size: 18px;
  }
  .iframe {
    width: 100% !important;
    height: 400% !important;
  }
  .Our-Solution-Container h1 {
    font-size: 40px !important;
    padding: 0% !important;
    padding-bottom: 15% !important;
  }
  .main-page-container {
    background-size: 97% auto, 150% auto, 100% auto, cover, cover !important;
    background-position: left 20px top 15%, right -7px top 153px, center, center !important;
  }
  .our-services-res {
    padding: 0 !important;
  }
  .Our-Services-Container {
    padding-right: 0px !important;
  }
  .soulbutton button {
    font-size: 15px !important;
  }
  .Our-Managment h2 {
    text-align: center !important;
  }
}

/* Media Query for Laptops and Desktops */

@media (min-width: 1025px) and (max-width: 1280px) {
  .smart-parking-res {
    padding-top: 1rem !important;
  }
  .smart-parking-res h2 {
    font-size: 30px !important;
    margin-bottom: 0px !important;
  }
  .Access-res {
    line-height: 50px !important;
  }
  .smart-parking-res p {
    font-size: 18px !important;
    padding-top: 0rem !important;
  }
  .main-container h1 {
    font-size: 50px;
    /* padding: 150px; */
  }
  .fac h2 {
    font-size: 36px !important;
  }
  .fac p {
    padding-top: 0px !important;
    font-size: 20px !important;
  }
  .fac {
    padding-top: 23px !important;
  }
  .fac ul {
    font-size: 16px !important;
  }
  .fac-2 h2 {
    font-size: 36px !important;
  }
  .fac-2 p {
    padding-top: 0px !important;
    font-size: 20px !important;
  }
  .fac-2 {
    padding-top: 30px !important;
  }
  .fac-2 ul {
    font-size: 16px !important;
  }
  .fac-3 h2 {
    font-size: 36px !important;
  }
  .fac-3 p {
    padding-top: 0px !important;
    font-size: 20px !important;
  }
  .fac-3 {
    padding-top: 30px !important;
  }
  .fac-2 ul {
    font-size: 16px !important;
  }
  .test-res {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .dropdown2 {
    z-index: 1;
  }
  .dropdown li:hover {
    color: #00aed9;
  }
  .dropdown li {
    font-size: 18px;
    text-align: center !important;
  }
  .dropdown2 li {
    font-size: 18px;
    text-align: center !important;
  }
  .about-res {
    padding: 8% !important;
  }
  .our-approch {
    height: 125vh !important;
  }
  .know-more-2 {
    padding-top: 48px !important;
  }
  .Our-Services-Container {
    padding-right: 0px !important;
  }
  .join-img-res {
    margin-left: 2px;
  }
  .contact-res h3 {
    font-size: 30px !important;
  }
  .contact-res p {
    width: auto;
    font-size: 20px !important;
  }
  .main-contact {
    background-size: cover, 105% auto, cover !important;
    background-position: center, right -530% top -17px !important;
  }
  .iframe {
    width: 100% !important;
    height: 300px !important;
  }
  .access-main-container {
    padding-bottom: 5% !important;
  }
  .facility-main-container {
    padding-bottom: 5% !important;
  }
  .smart-main-container {
    padding-bottom: 5% !important;
  }
  .who-are-we {
    padding: 3em 3em !important;
  }
  .main-page-container {
    background-image: url("./assets/images/Layerhero.svg"),
      url("./assets/images/Ellipse.svg"), url("./assets/images/Technologyy.png"),
      linear-gradient(to right, #0b1a57, #0b1a57, #071036e3, #000000a1),
      url("./assets/images/hero-container.png") !important;
    background-size: 78% auto, 87% auto, 5% auto, 100% auto, cover, cover !important;
    background-position: left 390px top 1%, right 511px top 148px,
      right 70px top 263px, center, center !important;
  }
  .Our-Services-Container {
    background-size: 0 auto, 150%, 100% !important;
    background-position: left 50px top 20%, left -52px top 79%, top !important;
  }
  .join-team {
    background-size: 50%, 100% auto !important;
    background-position: right -18% top -6px, right 0% top -0 !important;
    padding: 0 0 0 2rem;
    background-repeat: no-repeat;
    height: auto !important;
  }
  .join-team h4 {
    font-size: 23px;
  }
  .join-team h2 {
    font-size: 40px;
  }
  .solutions {
    margin-top: -62px !important;
    /* padding-bottom: 20% !important; */
  }
  .our-services-res {
    padding: 0 !important;
  }
  .services-res {
    padding: 0 !important;
    margin-top: 50px !important;
  }
  .srv-bnr-item-head {
    font-size: 38px !important;
  }
  .srv-bnr-desc {
    font-size: 14px !important;
  }
}

@media (min-width: 1700px) {
  .main-contact {
    background-size: cover, 90% auto, cover;
    background-position: center, right 137% top 44px !important;
  }
  .test-res {
    margin-left: 30rem !important;
    margin-right: 30rem !important;
  }
  .join-text {
    font-size: 68px !important;
  }
}

@media (min-width: 2560px) {
  .main-page-container {
    background-position: left 390px top 1%, right 327% top -90px,
      right 70px top 263px, center, center !important;
  }
  .test-res {
    margin-left: 40rem !important;
    margin-right: 40rem !important;
  }
  .join-team {
    background-size: 22%, 100% auto !important;
    background-position: 102%, right 0% top -0 !important;
    background-repeat: no-repeat;
    height: 44vh;
  }
  .main-contact {
    background-position: center, right 147% top -35px !important;
  }
}
