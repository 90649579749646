.our-culture {
  height: 70%;
  margin: 0px;
  width: 100%;
  background-image: linear-gradient(92deg, #03083ed9, #03083ed9),
    url("https://res.cloudinary.com/srikanth143/image/upload/v1687001053/Rectangle_222_2x_crflj6.png");

  color: white;
  background-position: 30% 29%;
  background-size: cover;
}

.our-culture-head {
  margin-top: 0%;
  font-size: 2.125rem;
  background-image: linear-gradient(
    to right,
    hsla(249, 100%, 81%, 1.2),
    hsla(207, 67%, 53%, 1),
    hsla(190, 94%, 51%, 1)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  width: 30vw;
  padding-left: 1.1em;
  padding-top: 2em;
}

.our-culture-para {
  font-size: 1rem;
  line-height: 1.93em;
  padding-left: 2em;
}

.aptitude {
  border: 2px solid;
  border-image: linear-gradient(
      100deg,
      hsla(233, 100%, 87%, 1.2),
      hsla(190, 97%, 53%, 1)
    )
    1;
  border-image-slice: 1;
  padding: 0.8em 1em;

  background-image: linear-gradient(
    to right,
    hsla(249, 100%, 81%),
    hsla(207, 67%, 53%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%),
    hsla(190, 94%, 51%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  width: auto;
  font-size: 1rem;
}
